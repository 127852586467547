import React, { Component } from "react";
import { Link } from "gatsby";

import MainLayout from "../layouts/MainLayout";
import Breadcrumb from "../components/common/breadcrumb";

import Recaptcha from "react-recaptcha";
import SEO from "../components/common/SEO";

import { encode } from "../utils";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "init",
      formData: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        message: "",
        subject: "",
      },
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: { ...prevState.formData, [name]: value },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let url = `${process.env.GATSBY_API_BASE_URL}/api/v1/marketplace/forms`;
    this.setState({ status: "submitting" });
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
      },
      body: JSON.stringify({
        ...this.state.formData,
        contactRequest: {
          isContact: true,
          message: this.state.formData.message,
          subject: this.state.formData.subject,
        },
      }),
    })
      .then((res) => {
        this.setState({ status: "success" });
      })
      .catch((err) => {
        this.setState({ status: "failed" });
        console.log(err);
      });
  };

  render() {
    const { firstName, lastName, phone, email, message, subject } =
      this.state.formData;

    return (
      <MainLayout>
        <SEO title="Jimenez Store Mr. Discount - Contact Page" />
        <div className="main">
          <h1 className="d-none">Jimenez Store Mr. Discount - Contact Page</h1>

          <Breadcrumb title="Contact Us" adClass="border-0 mb-0" />

          <div className="container">
            <div
              className="page-header page-header-big text-center"
              style={{
                backgroundImage: `url('assets/images/Jimenez-contact-us.jpg')`,
              }}
            >
              {/* <h1 className="page-title text-white">
                Contact us
                <span className="text-white">keep in touch with us</span>
              </h1> */}
            </div>
          </div>

          <div className="page-content pb-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mb-2 mb-lg-0">
                  <h2 className="title mb-1">Contact Information</h2>
                  <p className="mb-3">
                    Whether you have a question about new accounts, wholesale,
                    pricing, or anything else, our team is ready to answer all
                    your questions.
                  </p>
                  <div className="row">
                    <div className="col-sm-7">
                      <div className="contact-info">
                        <h3>Our Store</h3>

                        <ul className="contact-list">
                          <li>
                            <i className="icon-map-marker"></i>
                            6425 English Ave, Indianapolis, IN 46219
                          </li>
                          <li>
                            <i className="icon-phone"></i>
                            <Link to="tel:812-562-5622">+812-562-5622</Link>
                          </li>
                          <li>
                            <i className="icon-envelope"></i>
                            <Link to="mailto:contact@jimenezmrdiscount.com">
                              contact@jimenezmrdiscount.com
                            </Link>
                          </li>
                          <li>
                            <i className="icon-clock-o"></i> <b>Store hours:</b>
                            <br />
                            Monday - Friday 9am - 6pm
                            <br />
                            Saturday 9am - 5pm
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-sm-5"></div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h2 className="title mb-1">Got Any Questions?</h2>
                  <p className="mb-2">
                    Use the form below to get in touch with the sales team
                  </p>

                  {this.state.status === "init" && (
                    <form
                      action="#"
                      className="contact-form mb-3"
                      name="contact"
                      method="POST"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="row">
                        <div className="col-sm-6">
                          <label htmlFor="cname" className="sr-only">
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="firstName"
                            placeholder="Enter your first name"
                            required=""
                            value={firstName}
                            onChange={this.handleChange}
                          />
                        </div>{" "}
                        <div className="col-sm-6">
                          <label htmlFor="cname" className="sr-only">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="lastName"
                            placeholder="Enter your last name"
                            required=""
                            value={lastName}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <label htmlFor="cemail" className="sr-only">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Email"
                            required=""
                            value={email}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <label htmlFor="cphone" className="sr-only">
                            Phone
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder="Enter your number"
                            required=""
                            value={phone}
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="col-sm-6">
                          <label htmlFor="subject" className="sr-only">
                            Subject
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            rows="6"
                            placeholder="Write Your Subject"
                            id="subject"
                            name="subject"
                            value={subject}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <label htmlFor="cmessage" className="sr-only">
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        rows="4"
                        cols="30"
                        placeholder="Write Your Message"
                        id="exampleFormControlTextarea1"
                        name="message"
                        value={message}
                        onChange={this.handleChange}
                      ></textarea>
                      <div className="col-md-12 mb-4">
                        <Recaptcha
                          sitekey={`${process.env.GATSBY_SITE_RECAPTCHA_KEY}`}
                          render="explicit"
                          onloadCallback={() => {}}
                          verifyCallback={(res) =>
                            this.setState({ recaptcha: res })
                          }
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-outline-primary-2 btn-minwidth-sm"
                        disabled={
                          this.state.status === "submitting" ||
                          this.state.recaptcha === undefined
                        }
                      >
                        <span>SUBMIT</span>
                        <i className="icon-long-arrow-right"></i>
                      </button>
                    </form>
                  )}

                  {this.state.status === "success" && (
                    <div className="success-text">
                      <i className="fa fa-check-circle" aria-hidden="true"></i>
                      <h2>thank you</h2>
                      <p>Your response has been received successfully.</p>
                    </div>
                  )}

                  {this.state.status === "failed" && (
                    <div className="success-text">
                      <i
                        className="fa fa-times-circle"
                        aria-hidden="true"
                        style={{ color: "#ff4c3b" }}
                      ></i>
                      <h2>Oops!</h2>
                      <p>
                        Something went wrong while submitting the response.
                        Please refresh the page and try again.
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <hr className="mt-4 mb-2" />

              {/* <div className="stores mb-4 mb-lg-5">
                <h2 className="title text-center mb-3">Our Stores</h2>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="store">
                      <div className="row">
                        <div className="col-sm-5 col-xl-6">
                          <figure className="store-media mb-2 mb-lg-0">
                            <img
                              src={`assets/images/stores/img-1.jpg`}
                              alt="desc"
                            />
                          </figure>
                        </div>
                        <div className="col-sm-7 col-xl-6">
                          <div className="store-content">
                            <h3 className="store-title">Wall Street Plaza</h3>
                            <address>
                              88 Pine St, New York, NY 10005, USA
                            </address>
                            <div>
                              <Link to="tel:#">+1 987-876-6543</Link>
                            </div>

                            <h4 className="store-subtitle">Store Hours:</h4>
                            <div>Monday - Saturday 11am to 7pm</div>
                            <div>Sunday 11am to 6pm</div>

                            <Link
                              to="#"
                              className="btn btn-link"
                              target="_blank"
                            >
                              <span>View Map</span>
                              <i className="icon-long-arrow-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="store">
                      <div className="row">
                        <div className="col-sm-5 col-xl-6">
                          <figure className="store-media mb-2 mb-lg-0">
                            <img
                              src={`assets/images/stores/img-2.jpg`}
                              alt="desc"
                            />
                          </figure>
                        </div>

                        <div className="col-sm-7 col-xl-6">
                          <div className="store-content">
                            <h3 className="store-title">One New York Plaza</h3>
                            <address>
                              88 Pine St, New York, NY 10005, USA
                            </address>
                            <div>
                              <Link to="tel:#">+1 987-876-6543</Link>
                            </div>

                            <h4 className="store-subtitle">Store Hours:</h4>
                            <div>Monday - Friday 9am to 8pm</div>
                            <div>Saturday - 9am to 2pm</div>
                            <div>Sunday - Closed</div>

                            <Link
                              to="#"
                              className="btn btn-link"
                              target="_blank"
                            >
                              <span>View Map</span>
                              <i className="icon-long-arrow-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div id="map">
              <iframe
                src="https://www.google.com/maps/d/u/0/embed?mid=1AlrslDdoZm4-GL1VOtIEAMYqsV9fyDck&ll=39.760174853956%2C-86.05680679999999&z=18"
                title="Where are we located?"
              ></iframe>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default React.memo(ContactUs);
